import { BN } from "bn.js";
import BigNumber from "bignumber.js";
import bs58 from "bs58";
import {
    Keypair,
    PublicKey,
    SystemProgram,
    Transaction,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
    SYSVAR_RENT_PUBKEY,
    LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
    MINT_SIZE,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID,
    AuthorityType,
    getMint,
    getAccount,
    getMinimumBalanceForRentExemptMint,
    getAssociatedTokenAddress,
    createInitializeAccountInstruction,
    createInitializeMintInstruction,
    createAssociatedTokenAccountInstruction,
    createMintToInstruction,
    createSetAuthorityInstruction,
    createBurnInstruction,
    createCloseAccountInstruction,
    getAssociatedTokenAddressSync
} from "@solana/spl-token";
import {
    Token,
    TokenAmount,
    TxVersion,
    LOOKUP_TABLE_CACHE,
    DEVNET_PROGRAM_ID,
    MAINNET_PROGRAM_ID,
    SPL_ACCOUNT_LAYOUT,
    MARKET_STATE_LAYOUT_V2,
    InstructionType,
    Liquidity,
    generatePubKey,
    struct,
    u8,
    u16,
    u32,
    u64,
    splitTxAndSigners,
    Percent,
    jsonInfo2PoolKeys,
    poolKeys2JsonInfo,
    buildSimpleTransaction,
} from "@raydium-io/raydium-sdk";
import { Market, MARKET_STATE_LAYOUT_V3 } from "@project-serum/serum";
import {
    PROGRAM_ID,
    Metadata,
    createCreateMetadataAccountV3Instruction,
} from "@metaplex-foundation/mpl-token-metadata";

// import { Wallet, AnchorProvider, Program } from '@coral-xyz/anchor';
import { AnchorProvider, Program } from '@project-serum/anchor';

import {
    AmmImpl,
    PROGRAM_ID as AmmImplProgramId,
    Amm,
    Vault,
    // AmmIdl,
    // VaultIdl
} from "@mercurial-finance/dynamic-amm-sdk";
import axios from "axios";
import AmmIdl from "./ammIdl.json";
import VaultIdl from "./vaultIdl.json";

const SEEDS = Object.freeze({
    VAULT_PREFIX: 'vault',
    TOKEN_VAULT_PREFIX: 'token_vault',
    LP_MINT_PREFIX: 'lp_mint',
    COLLATERAL_VAULT_PREFIX: 'collateral_vault',
    OBLIGATION_PREFIX: 'obligation',
    OBLIGATION_OWNER_PREFIX: 'obligation_owner',
    STAKING_PREFIX: 'staking',
    MINER: 'Miner',
    QUARRY: 'Quarry',
    APRICOT_USER_INFO_SIGNER_PREFIX: 'apricot_user_info_signer',
    FRAKT: 'frakt',
    DEPOSIT: 'deposit',
    FRAKT_LENDING: 'nftlendingv2',
    CYPHER: 'cypher',
    MANGO_ACCOUNT: 'MangoAccount',
    MANGO: 'mango',
    PSYLEND: 'psylend',
    PSYLEND_OWNER: 'deposits',
    MARGINFI_STRATEGY: 'marginfi_strategy',
    MARGINFI_ACCOUNT: 'marginfi_account',
    APY: 'apy',
    FEE: 'fee',
    LP_MINT: 'lp_mint',
    LOCK_ESCROW: 'lock_escrow',
});

const VAULT_BASE_KEY = new PublicKey('HWzXGcGHy4tcpYfaRDCyLNzXqBTv3E6BttpCH2vJxArv');

const POOL_BASE_KEY = new PublicKey('H9NnqW5Thn9dUzW3DRXe2xDhKjwZd4qbjngnZwEvnDuC');

const NATIVE_MINT = new PublicKey('So11111111111111111111111111111111111111112');

const config = new PublicKey('87umT3FP4ezojp6p1e5ALXGSJWmn3h7pusmuk6t3o3Jg');

export const wrapSOLInstruction = (from, to, amount) => {
    return [
        SystemProgram.transfer({
            fromPubkey: from,
            toPubkey: to,
            lamports: amount,
        }),
        new TransactionInstruction({
            keys: [
                {
                    pubkey: to,
                    isSigner: false,
                    isWritable: true,
                },
            ],
            data: Buffer.from(new Uint8Array([17])),
            programId: TOKEN_PROGRAM_ID,
        }),
    ];
};

export const unwrapSOLInstruction = async (owner) => {
    const wSolATAAccount = await getAssociatedTokenAccount(NATIVE_MINT, owner);
    if (wSolATAAccount) {
        const closedWrappedSolInstruction = createCloseAccountInstruction(wSolATAAccount, owner, owner, []);
        return closedWrappedSolInstruction;
    }
    return null;
};

const getAssociatedTokenAccount = (tokenMint, owner) => {
    return getAssociatedTokenAddressSync(tokenMint, owner, true, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID);
};

const getOrCreateATAInstruction = async (
    tokenMint,
    owner,
    connection,
    payer,
) => {
    let toAccount;
    try {
        toAccount = await getAssociatedTokenAccount(tokenMint, owner);
        const account = await connection.getAccountInfo(toAccount);
        if (!account) {
            const ix = createAssociatedTokenAccountInstruction(
                payer || owner,
                toAccount,
                owner,
                tokenMint,
                TOKEN_PROGRAM_ID,
                ASSOCIATED_TOKEN_PROGRAM_ID,
            );
            return [toAccount, ix];
        }
        return [toAccount, undefined];
    } catch (e) {
        /* handle error */
        console.error('Error::getOrCreateATAInstruction', e);
        throw e;
    }
};


const JITO_TIMEOUT = 150000;
const PROGRAMIDS = (process.env.REACT_APP_DEVNET_MODE === "true") ? DEVNET_PROGRAM_ID : MAINNET_PROGRAM_ID;
const addLookupTableInfo = (process.env.REACT_APP_DEVNET_MODE === "true") ? undefined : LOOKUP_TABLE_CACHE;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function sleep(ms) {
    return new Promise(r => setTimeout(r, ms));
}

export const USE_JITO = true;

async function makeCreateMarketInstruction({
    connection,
    owner,
    baseInfo,
    quoteInfo,
    lotSize, // 1
    tickSize, // 0.01
    dexProgramId,
    makeTxVersion,
    lookupTableCache
}) {
    const market = generatePubKey({ fromPublicKey: owner, programId: dexProgramId });
    const requestQueue = generatePubKey({ fromPublicKey: owner, programId: dexProgramId });
    const eventQueue = generatePubKey({ fromPublicKey: owner, programId: dexProgramId });
    const bids = generatePubKey({ fromPublicKey: owner, programId: dexProgramId });
    const asks = generatePubKey({ fromPublicKey: owner, programId: dexProgramId });
    const baseVault = generatePubKey({ fromPublicKey: owner, programId: TOKEN_PROGRAM_ID });
    const quoteVault = generatePubKey({ fromPublicKey: owner, programId: TOKEN_PROGRAM_ID });
    const feeRateBps = 0;
    const quoteDustThreshold = new BN(100);

    function getVaultOwnerAndNonce() {
        const vaultSignerNonce = new BN(0);
        while (true) {
            try {
                const vaultOwner = PublicKey.createProgramAddressSync([market.publicKey.toBuffer(), vaultSignerNonce.toArrayLike(Buffer, 'le', 8)], dexProgramId);
                return { vaultOwner, vaultSignerNonce };
            }
            catch (e) {
                vaultSignerNonce.iaddn(1);
                if (vaultSignerNonce.gt(new BN(25555)))
                    throw Error('find vault owner error');
            }
        }
    }

    function initializeMarketInstruction({ programId, marketInfo }) {
        const dataLayout = struct([
            u8('version'),
            u32('instruction'),
            u64('baseLotSize'),
            u64('quoteLotSize'),
            u16('feeRateBps'),
            u64('vaultSignerNonce'),
            u64('quoteDustThreshold'),
        ]);

        const keys = [
            { pubkey: marketInfo.id, isSigner: false, isWritable: true },
            { pubkey: marketInfo.requestQueue, isSigner: false, isWritable: true },
            { pubkey: marketInfo.eventQueue, isSigner: false, isWritable: true },
            { pubkey: marketInfo.bids, isSigner: false, isWritable: true },
            { pubkey: marketInfo.asks, isSigner: false, isWritable: true },
            { pubkey: marketInfo.baseVault, isSigner: false, isWritable: true },
            { pubkey: marketInfo.quoteVault, isSigner: false, isWritable: true },
            { pubkey: marketInfo.baseMint, isSigner: false, isWritable: false },
            { pubkey: marketInfo.quoteMint, isSigner: false, isWritable: false },
            // Use a dummy address if using the new dex upgrade to save tx space.
            {
                pubkey: marketInfo.authority ? marketInfo.quoteMint : SYSVAR_RENT_PUBKEY,
                isSigner: false,
                isWritable: false,
            },
        ]
            .concat(marketInfo.authority ? { pubkey: marketInfo.authority, isSigner: false, isWritable: false } : [])
            .concat(
                marketInfo.authority && marketInfo.pruneAuthority
                    ? { pubkey: marketInfo.pruneAuthority, isSigner: false, isWritable: false }
                    : [],
            );

        const data = Buffer.alloc(dataLayout.span);
        dataLayout.encode(
            {
                version: 0,
                instruction: 0,
                baseLotSize: marketInfo.baseLotSize,
                quoteLotSize: marketInfo.quoteLotSize,
                feeRateBps: marketInfo.feeRateBps,
                vaultSignerNonce: marketInfo.vaultSignerNonce,
                quoteDustThreshold: marketInfo.quoteDustThreshold,
            },
            data,
        );

        return new TransactionInstruction({
            keys,
            programId,
            data,
        });
    }

    const { vaultOwner, vaultSignerNonce } = getVaultOwnerAndNonce();

    const ZERO = new BN(0);
    const baseLotSize = new BN(Math.round(10 ** baseInfo.decimals * lotSize).toFixed(0));
    const quoteLotSize = new BN(Math.round(lotSize * 10 ** quoteInfo.decimals * tickSize).toFixed(0));
    if (baseLotSize.eq(ZERO))
        throw Error('lot size is too small');
    if (quoteLotSize.eq(ZERO))
        throw Error('tick size or lot size is too small');

    const ins1 = [];
    const accountLamports = await connection.getMinimumBalanceForRentExemption(165);
    ins1.push(
        SystemProgram.createAccountWithSeed({
            fromPubkey: owner,
            basePubkey: owner,
            seed: baseVault.seed,
            newAccountPubkey: baseVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: owner,
            basePubkey: owner,
            seed: quoteVault.seed,
            newAccountPubkey: quoteVault.publicKey,
            lamports: accountLamports,
            space: 165,
            programId: TOKEN_PROGRAM_ID,
        }),
        createInitializeAccountInstruction(baseVault.publicKey, baseInfo.mint, vaultOwner),
        createInitializeAccountInstruction(quoteVault.publicKey, quoteInfo.mint, vaultOwner),
    );

    const EVENT_QUEUE_ITEMS = 128; // Default: 2978
    const REQUEST_QUEUE_ITEMS = 63; // Default: 63
    const ORDERBOOK_ITEMS = 201; // Default: 909

    const eventQueueSpace = EVENT_QUEUE_ITEMS * 88 + 44 + 48;
    const requestQueueSpace = REQUEST_QUEUE_ITEMS * 80 + 44 + 48;
    const orderBookSpace = ORDERBOOK_ITEMS * 80 + 44 + 48;

    const ins2 = [];
    ins2.push(
        SystemProgram.createAccountWithSeed({
            fromPubkey: owner,
            basePubkey: owner,
            seed: market.seed,
            newAccountPubkey: market.publicKey,
            lamports: await connection.getMinimumBalanceForRentExemption(MARKET_STATE_LAYOUT_V2.span),
            space: MARKET_STATE_LAYOUT_V2.span,
            programId: dexProgramId,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: owner,
            basePubkey: owner,
            seed: requestQueue.seed,
            newAccountPubkey: requestQueue.publicKey,
            lamports: await connection.getMinimumBalanceForRentExemption(requestQueueSpace),
            space: requestQueueSpace,
            programId: dexProgramId,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: owner,
            basePubkey: owner,
            seed: eventQueue.seed,
            newAccountPubkey: eventQueue.publicKey,
            lamports: await connection.getMinimumBalanceForRentExemption(eventQueueSpace),
            space: eventQueueSpace,
            programId: dexProgramId,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: owner,
            basePubkey: owner,
            seed: bids.seed,
            newAccountPubkey: bids.publicKey,
            lamports: await connection.getMinimumBalanceForRentExemption(orderBookSpace),
            space: orderBookSpace,
            programId: dexProgramId,
        }),
        SystemProgram.createAccountWithSeed({
            fromPubkey: owner,
            basePubkey: owner,
            seed: asks.seed,
            newAccountPubkey: asks.publicKey,
            lamports: await connection.getMinimumBalanceForRentExemption(orderBookSpace),
            space: orderBookSpace,
            programId: dexProgramId,
        }),
        initializeMarketInstruction({
            programId: dexProgramId,
            marketInfo: {
                id: market.publicKey,
                requestQueue: requestQueue.publicKey,
                eventQueue: eventQueue.publicKey,
                bids: bids.publicKey,
                asks: asks.publicKey,
                baseVault: baseVault.publicKey,
                quoteVault: quoteVault.publicKey,
                baseMint: baseInfo.mint,
                quoteMint: quoteInfo.mint,
                baseLotSize: baseLotSize,
                quoteLotSize: quoteLotSize,
                feeRateBps: feeRateBps,
                vaultSignerNonce: vaultSignerNonce,
                quoteDustThreshold: quoteDustThreshold,
            },
        }),
    );

    const ins = {
        address: {
            marketId: market.publicKey,
            requestQueue: requestQueue.publicKey,
            eventQueue: eventQueue.publicKey,
            bids: bids.publicKey,
            asks: asks.publicKey,
            baseVault: baseVault.publicKey,
            quoteVault: quoteVault.publicKey,
            baseMint: baseInfo.mint,
            quoteMint: quoteInfo.mint,
        },
        innerTransactions: [
            {
                instructions: ins1,
                signers: [],
                instructionTypes: [
                    InstructionType.createAccount,
                    InstructionType.createAccount,
                    InstructionType.initAccount,
                    InstructionType.initAccount,
                ],
            },
            {
                instructions: ins2,
                signers: [],
                instructionTypes: [
                    InstructionType.createAccount,
                    InstructionType.createAccount,
                    InstructionType.createAccount,
                    InstructionType.createAccount,
                    InstructionType.createAccount,
                    InstructionType.initMarket,
                ],
            },
        ]
    };

    return {
        address: ins.address,
        innerTransactions: await splitTxAndSigners({
            connection,
            makeTxVersion,
            computeBudgetConfig: undefined,
            payer: owner,
            innerTransaction: ins.innerTransactions,
            lookupTableCache,
        }),
    };
}

export async function getTipTransaction(connection, ownerPubkey, tip) {
    try {
        const { data } = await axios.post("https://mainnet.block-engine.jito.wtf/api/v1/bundles",
            {
                jsonrpc: "2.0",
                id: 1,
                method: "getTipAccounts",
                params: [],
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const tipAddrs = data.result;
        // const getRandomNumber = (min, max) => {
        //     return Math.floor(Math.random() * (max - min + 1)) + min;
        // };
        console.log("Adding tip transactions...", tip);

        const tipAccount = new PublicKey(tipAddrs[0]);
        const instructions = [
            SystemProgram.transfer({
                fromPubkey: ownerPubkey,
                toPubkey: tipAccount,
                lamports: LAMPORTS_PER_SOL * tip,
            })
        ];
        const recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        const messageV0 = new TransactionMessage({
            payerKey: ownerPubkey,
            recentBlockhash,
            instructions,
        }).compileToV0Message();

        return new VersionedTransaction(messageV0);
    }
    catch (err) {
        console.log(err);
    }
    return null;
}

export async function getWalletTokenAccount(connection, ownerPubkey) {
    const walletTokenAccount = await connection.getTokenAccountsByOwner(ownerPubkey, {
        programId: TOKEN_PROGRAM_ID,
    });
    return walletTokenAccount.value.map((item) => ({
        pubkey: item.pubkey,
        programId: item.account.owner,
        accountInfo: SPL_ACCOUNT_LAYOUT.decode(item.account.data),
    }));
}

export async function getTokenListByOwner(connection, ownerPubkey, queryMarketId) {
    const walletTokenAccount = await connection.getTokenAccountsByOwner(ownerPubkey, {
        programId: TOKEN_PROGRAM_ID,
    });
    const tokenList = await Promise.all(walletTokenAccount.value.map(async (item) => {
        const accountInfo = SPL_ACCOUNT_LAYOUT.decode(item.account.data);
        const mintInfo = await getMint(connection, accountInfo.mint);
        const [metadataPDA] = PublicKey.findProgramAddressSync(
            [
                Buffer.from("metadata"),
                PROGRAM_ID.toBuffer(),
                accountInfo.mint.toBuffer()
            ],
            PROGRAM_ID
        );

        let marketId = null;
        if (queryMarketId) {
            const quoteMint = new PublicKey("So11111111111111111111111111111111111111112");
            const marketAccounts = await Market.findAccountsByMints(connection, accountInfo.mint, quoteMint, PROGRAMIDS.OPENBOOK_MARKET);
            if (marketAccounts.length > 0)
                marketId = marketAccounts[0].publicKey;
        }

        let tokenName = "";
        let tokenSymbol = "";
        let logoURI = "";
        try {
            const metadata = await Metadata.fromAccountAddress(connection, metadataPDA);
            try {
                const tNames = metadata.data.name.split('\0');
                const tSymbols = metadata.data.symbol.split('\0');
                tokenName = tNames[0];
                tokenSymbol = tSymbols[0];
            }
            catch (err) {
                console.log(err);
                tokenName = metadata.data.name;
                tokenSymbol = metadata.data.symbol;
            }

            try {
                console.log(metadata.data.uri);
                const { data } = await axios.get(metadata.data.uri);
                if (data.image)
                    logoURI = data.image;
                else
                    logoURI = metadata.data.uri;
            }
            catch (err) {
                console.log(err);
            }
        }
        catch (err) {
            console.log(err);
        }

        return {
            name: tokenName,
            symbol: tokenSymbol,
            logoURI: logoURI,
            mint: accountInfo.mint.toBase58(),
            account: item.pubkey.toBase58(),
            balance: accountInfo.amount.div(new BN(Math.pow(10, mintInfo.decimals).toFixed(0))).toString(),
            marketId: (queryMarketId && marketId) ? marketId : undefined,
        };
    }));
    return tokenList;
}

export async function getPoolInfo(connection, token) {
    console.log("Getting pool info...", token);

    if (!token) {
        console.log("Invalid token address");
        return {};
    }

    const mint = new PublicKey(token);
    const mintInfo = await getMint(connection, mint);

    const baseToken = new Token(TOKEN_PROGRAM_ID, token, mintInfo.decimals);
    const quoteToken = new Token(TOKEN_PROGRAM_ID, "So11111111111111111111111111111111111111112", 9, "WSOL", "WSOL");

    const marketAccounts = await Market.findAccountsByMints(connection, baseToken.mint, quoteToken.mint, PROGRAMIDS.OPENBOOK_MARKET);
    if (marketAccounts.length === 0) {
        console.log("Not found market info");
        return {};
    }

    const marketInfo = MARKET_STATE_LAYOUT_V3.decode(marketAccounts[0].accountInfo.data);
    let poolKeys = Liquidity.getAssociatedPoolKeys({
        version: 4,
        marketVersion: 4,
        baseMint: baseToken.mint,
        quoteMint: quoteToken.mint,
        baseDecimals: baseToken.decimals,
        quoteDecimals: quoteToken.decimals,
        marketId: marketAccounts[0].publicKey,
        programId: PROGRAMIDS.AmmV4,
        marketProgramId: PROGRAMIDS.OPENBOOK_MARKET,
    });
    poolKeys.marketBaseVault = marketInfo.baseVault;
    poolKeys.marketQuoteVault = marketInfo.quoteVault;
    poolKeys.marketBids = marketInfo.bids;
    poolKeys.marketAsks = marketInfo.asks;
    poolKeys.marketEventQueue = marketInfo.eventQueue;

    const poolInfo = poolKeys2JsonInfo(poolKeys);
    return poolInfo;
}

export async function getLPBalance(connection, baseMintAddress, quoteMintAddress, ownerPubkey) {
    if (!baseMintAddress || !quoteMintAddress) {
        console.log("Invalid base or quote token address");
        return 0;
    }

    try {
        const baseMint = new PublicKey(baseMintAddress);
        const baseMintInfo = await getMint(connection, baseMint);

        const quoteMint = new PublicKey(quoteMintAddress);
        const quoteMintInfo = await getMint(connection, quoteMint);

        const baseToken = new Token(TOKEN_PROGRAM_ID, baseMint, baseMintInfo.decimals);
        const quoteToken = new Token(TOKEN_PROGRAM_ID, quoteMint, quoteMintInfo.decimals);

        const marketAccounts = await Market.findAccountsByMints(connection, baseToken.mint, quoteToken.mint, PROGRAMIDS.OPENBOOK_MARKET);
        if (marketAccounts.length === 0) {
            console.log("Not found market info");
            return 0;
        }

        for (let i = 0; i < marketAccounts.length; i++) {
            const poolKeys = Liquidity.getAssociatedPoolKeys({
                version: 4,
                marketVersion: 4,
                baseMint: baseToken.mint,
                quoteMint: quoteToken.mint,
                baseDecimals: baseToken.decimals,
                quoteDecimals: quoteToken.decimals,
                marketId: marketAccounts[i].publicKey,
                programId: PROGRAMIDS.AmmV4,
                marketProgramId: PROGRAMIDS.OPENBOOK_MARKET,
            });
            console.log("LP Mint:", poolKeys.lpMint.toBase58());

            try {
                const lpATA = await getAssociatedTokenAddress(poolKeys.lpMint, ownerPubkey);
                const lpAccount = await getAccount(connection, lpATA);
                const balance = new BN(lpAccount.amount).div(new BN(Math.pow(10, poolKeys.lpDecimals).toFixed(0))).toString();
                console.log("LP Balance:", balance);
                return balance;
            }
            catch (err) {
                console.log(err);
            }
        }
        return 0;
    }
    catch (err) {
        console.log(err);
    }
    return 0;
}

export async function sendAndConfirmSignedTransactions(useJito, connection, transactions) {
    if (useJito) {
        try {

            transactions.forEach(async tx => {
                let simResult = await connection.simulateTransaction(tx);
                console.log("======== Simulation Result:\n", simResult.value.logs);
            });
            const rawTxns = transactions.map(item => bs58.encode(item.serialize()));
            // const verTxns = base64Txns.map(item => VersionedTransaction.deserialize(Buffer.from(item, "base64")));
            // const rawTxns = verTxns.map(item => bs58.encode(item.serialize()));
            const { data: bundleRes } = await axios.post(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`,
                {
                    jsonrpc: "2.0",
                    id: 1,
                    method: "sendBundle",
                    params: [
                        rawTxns
                    ],
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (bundleRes) {
                const bundleId = bundleRes.result;
                console.log("Checking bundle's status...", bundleId);

                const sentTime = Date.now();
                while (Date.now() - sentTime < JITO_TIMEOUT) {
                    try {
                        const { data: bundleStat } = await axios.post(`https://mainnet.block-engine.jito.wtf/api/v1/bundles`,
                            {
                                jsonrpc: "2.0",
                                id: 1,
                                method: "getBundleStatuses",
                                params: [
                                    [
                                        bundleId
                                    ]
                                ],
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            }
                        );

                        if (bundleStat) {
                            const bundleStatuses = bundleStat.result.value;
                            console.log("Bundle Statuses:", bundleStatuses);
                            const matched = bundleStatuses.find(item => item.bundle_id === bundleId);
                            if (matched && matched.confirmation_status === "finalized")
                                return true;
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }

                    await sleep(1000);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }
    else {
        let retries = 50;
        let passed = {};

        const rawTransactions = transactions.map(transaction => {
            return transaction.serialize();
        });

        while (retries > 0) {
            try {
                let pendings = {};
                for (let i = 0; i < rawTransactions.length; i++) {
                    if (!passed[i]) {
                        pendings[i] = connection.sendRawTransaction(rawTransactions[i], {
                            skipPreflight: true,
                            maxRetries: 1,
                        });
                    }
                }

                let signatures = {};
                for (let i = 0; i < rawTransactions.length; i++) {
                    if (!passed[i])
                        signatures[i] = await pendings[i];
                }

                const sentTime = Date.now();
                while (Date.now() - sentTime <= 1000) {
                    for (let i = 0; i < rawTransactions.length; i++) {
                        if (!passed[i]) {
                            const ret = await connection.getParsedTransaction(signatures[i], {
                                commitment: "finalized",
                                maxSupportedTransactionVersion: 0,
                            });
                            if (ret) {
                                // console.log("Slot:", ret.slot);
                                // if (ret.transaction) {
                                //     console.log("Signatures:", ret.transaction.signatures);
                                //     console.log("Message:", ret.transaction.message);
                                // }
                                passed[i] = true;
                            }
                        }
                    }

                    let done = true;
                    for (let i = 0; i < rawTransactions.length; i++) {
                        if (!passed[i]) {
                            done = false;
                            break;
                        }
                    }

                    if (done)
                        return true;

                    await sleep(500);
                }
            }
            catch (err) {
                console.log(err);
            }
            retries--;
        }
    }

    return false;
}

export async function createToken(connection, ownerPubkey, name, symbol, uri, decimals, totalSupply, isPumpToken, freezeAuthority = false) {
    // console.log("Creating token transaction...", name, symbol, decimals, totalSupply);
    const lamports = await getMinimumBalanceForRentExemptMint(connection);


    let secretKey = "";

    if (isPumpToken) {
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/pumpfun/get_pump_key`, {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            console.log(data);

            if (data.success === true) {
                secretKey = data.secretKey;
                console.log("Success to get pump keypair!\n", data.secretKey)
            }
            else
                console.log("Failed to get pump keypair!");
        }
        catch (err) {
            console.log(err)
            console.log("Failed to get pump keypair!");
        }
    }


    let mintKeypair;

    try {
        mintKeypair = secretKey.toString().length === 0 ? Keypair.generate() : Keypair.fromSecretKey(bs58.decode(secretKey));
    }
    catch (err) {
        mintKeypair = Keypair.generate()
    }

    console.log("----- new mint address: ", mintKeypair.publicKey);

    const tokenATA = await getAssociatedTokenAddress(mintKeypair.publicKey, ownerPubkey);

    const [metadataPDA] = PublicKey.findProgramAddressSync(
        [
            Buffer.from("metadata"),
            PROGRAM_ID.toBuffer(),
            mintKeypair.publicKey.toBuffer()
        ],
        PROGRAM_ID
    );
    // console.log("Metadata PDA:", metadataPDA.toBase58());

    const tokenMetadata = {
        name: name,
        symbol: symbol,
        uri: uri,
        sellerFeeBasisPoints: 0,
        creators: null,
        collection: null,
        uses: null,
    };

    const instructions = [
        SystemProgram.createAccount({
            fromPubkey: ownerPubkey,
            newAccountPubkey: mintKeypair.publicKey,
            space: MINT_SIZE,
            lamports: lamports,
            programId: TOKEN_PROGRAM_ID,
        }),
        createInitializeMintInstruction(
            mintKeypair.publicKey,
            decimals,
            ownerPubkey,
            null,
            TOKEN_PROGRAM_ID
        ),
        createAssociatedTokenAccountInstruction(
            ownerPubkey,
            tokenATA,
            ownerPubkey,
            mintKeypair.publicKey,
        ),
        createMintToInstruction(
            mintKeypair.publicKey,
            tokenATA,
            ownerPubkey,
            totalSupply * Math.pow(10, decimals),
        ),
        createCreateMetadataAccountV3Instruction(
            {
                metadata: metadataPDA,
                mint: mintKeypair.publicKey,
                mintAuthority: ownerPubkey,
                payer: ownerPubkey,
                updateAuthority: ownerPubkey,
            },
            {
                createMetadataAccountArgsV3: {
                    data: tokenMetadata,
                    isMutable: true,
                    collectionDetails: null,
                },
            }
        )
    ];

    if (freezeAuthority == true) {
        instructions.push(
            createSetAuthorityInstruction(
                mintKeypair.publicKey,
                ownerPubkey,
                AuthorityType.MintTokens,
                null,
            ),
            createSetAuthorityInstruction(
                mintKeypair.publicKey,
                ownerPubkey,
                AuthorityType.FreezeAccount,
                null,
            )
        )
    }
    const recentBlockhash = (await connection.getLatestBlockhash("finalized")).blockhash;
    const message = new TransactionMessage({
        payerKey: ownerPubkey,
        recentBlockhash,
        instructions,
    });
    const transaction = new VersionedTransaction(message.compileToV0Message(Object.values({ ...(addLookupTableInfo ?? {}) })));
    transaction.sign([mintKeypair]);

    return { mint: mintKeypair.publicKey, transaction: transaction };
}

export async function setMintAuthority(connection, mintAddress, ownerPubkey, newAuthority) {
    const mint = new PublicKey(mintAddress);

    const transaction = new Transaction().add(
        createSetAuthorityInstruction(
            mint,
            ownerPubkey,
            AuthorityType.MintTokens,
            newAuthority ? new PublicKey(newAuthority) : null,
        )
    );
    transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
    transaction.feePayer = ownerPubkey;

    return transaction;
}

export async function setFreezeAuthority(connection, mintAddress, ownerPubkey, newAuthority) {
    const mint = new PublicKey(mintAddress);

    const transaction = new Transaction().add(
        createSetAuthorityInstruction(
            mint,
            ownerPubkey,
            AuthorityType.FreezeAccount,
            newAuthority ? new PublicKey(newAuthority) : null,
        )
    );
    transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
    transaction.feePayer = ownerPubkey;

    return transaction;
}

export async function closeTokenAccount(connection, mintAddress, ownerPubkey) {
    const mint = new PublicKey(mintAddress);
    const tokenATA = await getAssociatedTokenAddress(mint, ownerPubkey);
    const tx = new Transaction().add(
        createCloseAccountInstruction(tokenATA, ownerPubkey, ownerPubkey)
    );
    tx.feePayer = ownerPubkey;
    tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

    return tx;
}

export async function burnTokenByPercent(connection, mintAddress, percent, ownerPubkey) {
    const mint = new PublicKey(mintAddress);
    const tokenATA = await getAssociatedTokenAddress(mint, ownerPubkey);
    const tokenAccount = await getAccount(connection, tokenATA);
    const bnAmount = new BigNumber(tokenAccount.amount.toString()).dividedBy(new BigNumber(percent.toString())).multipliedBy(new BigNumber("100"));
    const tx = new Transaction().add(
        createBurnInstruction(tokenAccount.address, mint, ownerPubkey, bnAmount.toFixed(0))
    );
    tx.feePayer = ownerPubkey;
    tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

    return tx;
}

export async function createOpenBookMarket(connection, baseMintAddress, quoteMintAddress, lotSize, tickSize, ownerPubkey) {
    console.log("Creating OpenBook Market...", baseMintAddress, lotSize, tickSize, PROGRAMIDS.OPENBOOK_MARKET.toBase58());

    const baseMint = new PublicKey(baseMintAddress);
    const baseMintInfo = await getMint(connection, baseMint);

    const quoteMint = new PublicKey(quoteMintAddress);
    const quoteMintInfo = await getMint(connection, quoteMint);

    const marketAccounts = await Market.findAccountsByMints(connection, baseMint, quoteMint, PROGRAMIDS.OPENBOOK_MARKET);
    if (marketAccounts.length > 0) {
        console.log("Already created OpenBook market!");
        return { marketId: marketAccounts[0].publicKey };
    }

    const baseToken = new Token(TOKEN_PROGRAM_ID, baseMint, baseMintInfo.decimals);
    const quoteToken = new Token(TOKEN_PROGRAM_ID, quoteMint, quoteMintInfo.decimals);

    // -------- step 1: make instructions --------
    const { innerTransactions, address } = await makeCreateMarketInstruction({
        connection,
        owner: ownerPubkey,
        baseInfo: baseToken,
        quoteInfo: quoteToken,
        lotSize: lotSize,
        tickSize: tickSize,
        dexProgramId: PROGRAMIDS.OPENBOOK_MARKET,
        makeTxVersion: TxVersion.V0,
    });

    const transactions = await buildSimpleTransaction({
        connection,
        makeTxVersion: TxVersion.V0,
        payer: ownerPubkey,
        innerTransactions,
        addLookupTableInfo
    });

    return { marketId: address.marketId, transactions };
}

export async function createPool(connection, baseMintAddress, baseMintAmount, quoteMintAddress, quoteMintAmount, marketId, ownerPubkey) {
    const baseMint = new PublicKey(baseMintAddress);
    const baseMintInfo = await getMint(connection, baseMint);

    const quoteMint = new PublicKey(quoteMintAddress);
    const quoteMintInfo = await getMint(connection, quoteMint);

    const baseToken = new Token(TOKEN_PROGRAM_ID, baseMint, baseMintInfo.decimals);
    const quoteToken = new Token(TOKEN_PROGRAM_ID, quoteMint, quoteMintInfo.decimals);

    const baseAmount = new BN(new BigNumber(baseMintAmount.toString() + "e" + baseMintInfo.decimals.toString()).toFixed(0));
    const quoteAmount = new BN(new BigNumber(quoteMintAmount.toString() + "e" + quoteMintInfo.decimals.toString()).toFixed(0));
    const walletTokenAccounts = await getWalletTokenAccount(connection, ownerPubkey);
    const startTime = Math.floor(Date.now() / 1000);

    const { innerTransactions } = await Liquidity.makeCreatePoolV4InstructionV2Simple({
        connection,
        programId: PROGRAMIDS.AmmV4,
        marketInfo: {
            marketId: new PublicKey(marketId),
            programId: PROGRAMIDS.OPENBOOK_MARKET,
        },
        baseMintInfo: baseToken,
        quoteMintInfo: quoteToken,
        baseAmount: baseAmount,
        quoteAmount: quoteAmount,
        startTime: new BN(startTime),
        ownerInfo: {
            feePayer: ownerPubkey,
            wallet: ownerPubkey,
            tokenAccounts: walletTokenAccounts,
            useSOLBalance: true,
        },
        associatedOnly: false,
        checkCreateATAOwner: true,
        makeTxVersion: TxVersion.V0,
        feeDestinationId: (process.env.REACT_APP_DEVNET_MODE === "true") ? new PublicKey("3XMrhbv989VxAMi3DErLV9eJht1pHppW5LbKxe9fkEFR") : new PublicKey('7YttLkHDoNj9wyDur5pM1ejNaAvT9X4eqaYcHQqtj2G5'),
    });

    const transactions = await buildSimpleTransaction({
        connection,
        makeTxVersion: TxVersion.V0,
        payer: ownerPubkey,
        innerTransactions,
    });

    return transactions;
}

export async function removeLiquidityByPercent(connection, baseMintAddress, quoteMintAddress, percent, ownerPubkey) {
    const baseMint = new PublicKey(baseMintAddress);
    const baseMintInfo = await getMint(connection, baseMint);

    const quoteMint = new PublicKey(quoteMintAddress);
    const quoteMintInfo = await getMint(connection, quoteMint);

    const baseToken = new Token(TOKEN_PROGRAM_ID, baseMint, baseMintInfo.decimals);
    const quoteToken = new Token(TOKEN_PROGRAM_ID, quoteMint, quoteMintInfo.decimals);

    const marketAccounts = await Market.findAccountsByMints(connection, baseToken.mint, quoteToken.mint, PROGRAMIDS.OPENBOOK_MARKET);
    if (marketAccounts.length === 0) {
        console.log("Not found market info");
        return null;
    }
    console.log("Market Accounts:", marketAccounts);

    const walletTokenAccounts = await getWalletTokenAccount(connection, ownerPubkey);
    for (let i = 0; i < marketAccounts.length; i++) {
        const marketInfo = MARKET_STATE_LAYOUT_V3.decode(marketAccounts[i].accountInfo.data);
        console.log("Market Info:", marketInfo);

        let poolKeys = Liquidity.getAssociatedPoolKeys({
            version: 4,
            marketVersion: 3,
            baseMint: baseToken.mint,
            quoteMint: quoteToken.mint,
            baseDecimals: baseToken.decimals,
            quoteDecimals: quoteToken.decimals,
            marketId: marketAccounts[i].publicKey,
            programId: PROGRAMIDS.AmmV4,
            marketProgramId: PROGRAMIDS.OPENBOOK_MARKET,
        });

        try {
            const lpToken = new Token(TOKEN_PROGRAM_ID, poolKeys.lpMint, poolKeys.lpDecimals);
            const lpATA = await getAssociatedTokenAddress(poolKeys.lpMint, ownerPubkey);
            const lpAccount = await getAccount(connection, lpATA);
            const bnAmount = new BigNumber(lpAccount.amount.toString()).dividedBy(new BigNumber(percent.toString())).multipliedBy(new BigNumber("100"));
            const amountIn = new TokenAmount(lpToken, bnAmount.toFixed(0));

            poolKeys.marketBaseVault = marketInfo.baseVault;
            poolKeys.marketQuoteVault = marketInfo.quoteVault;
            poolKeys.marketBids = marketInfo.bids;
            poolKeys.marketAsks = marketInfo.asks;
            poolKeys.marketEventQueue = marketInfo.eventQueue;

            const { innerTransactions } = await Liquidity.makeRemoveLiquidityInstructionSimple({
                connection,
                poolKeys,
                userKeys: {
                    owner: ownerPubkey,
                    payer: ownerPubkey,
                    tokenAccounts: walletTokenAccounts,
                },
                amountIn: amountIn,
                makeTxVersion: TxVersion.V0,
            });

            const transactions = await buildSimpleTransaction({
                connection,
                makeTxVersion: TxVersion.V0,
                payer: ownerPubkey,
                innerTransactions,
                addLookupTableInfo,
            });

            return transactions;
        }
        catch (err) {
            console.log(err);
        }
    }

    return null;
}

export async function burnLPByPercent(connection, baseMintAddress, quoteMintAddress, percent, ownerPubkey) {
    const baseMint = new PublicKey(baseMintAddress);
    const baseMintInfo = await getMint(connection, baseMint);

    const quoteMint = new PublicKey(quoteMintAddress);
    const quoteMintInfo = await getMint(connection, quoteMint);

    const baseToken = new Token(TOKEN_PROGRAM_ID, baseMint, baseMintInfo.decimals);
    const quoteToken = new Token(TOKEN_PROGRAM_ID, quoteMint, quoteMintInfo.decimals);

    const marketAccounts = await Market.findAccountsByMints(connection, baseToken.mint, quoteToken.mint, PROGRAMIDS.OPENBOOK_MARKET);
    if (marketAccounts.length === 0) {
        console.log("Not found market info");
        return null;
    }
    console.log("Market Accounts:", marketAccounts);

    for (let i = 0; i < marketAccounts.length; i++) {
        const marketInfo = MARKET_STATE_LAYOUT_V3.decode(marketAccounts[i].accountInfo.data);
        console.log("Market Info:", marketInfo);

        let poolKeys = Liquidity.getAssociatedPoolKeys({
            version: 4,
            marketVersion: 3,
            baseMint: baseToken.mint,
            quoteMint: quoteToken.mint,
            baseDecimals: baseToken.decimals,
            quoteDecimals: quoteToken.decimals,
            marketId: marketAccounts[i].publicKey,
            programId: PROGRAMIDS.AmmV4,
            marketProgramId: PROGRAMIDS.OPENBOOK_MARKET,
        });

        try {
            const lpATA = await getAssociatedTokenAddress(poolKeys.lpMint, ownerPubkey);
            const lpAccount = await getAccount(connection, lpATA);
            const bnAmount = new BigNumber(lpAccount.amount.toString()).dividedBy(new BigNumber(percent.toString())).multipliedBy(new BigNumber("100"));
            const tx = new Transaction().add(
                createBurnInstruction(lpAccount.address, poolKeys.lpMint, ownerPubkey, bnAmount.toFixed(0))
            );
            tx.feePayer = ownerPubkey;
            tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

            return tx;
        }
        catch (err) {
            console.log(err);
        }
    }

    return null;
}

const VAULT_PROGRAM_ID = "24Uqj9JCLxUeoC3hGfh5W3s9FM9uCHDS2SG3LYwBpyTi";

export async function createPoolMeteora(connection, tokenAMint, tokenAAmount, tokenBAmount, payerWallet) {
    console.log("here", AmmImplProgramId);

    const tokenBMint = new PublicKey('So11111111111111111111111111111111111111112');

    const baseMint = new PublicKey(tokenAMint);
    const baseMintInfo = await getMint(connection, baseMint);

    const quoteMint = new PublicKey(tokenBMint);
    const quoteMintInfo = await getMint(connection, quoteMint);

    const baseAmount = new BN(new BigNumber(tokenAAmount.toString() + "e" + baseMintInfo.decimals.toString()).toFixed(0));
    const quoteAmount = new BN(new BigNumber(tokenBAmount.toString() + "e" + quoteMintInfo.decimals.toString()).toFixed(0));

    const provider = new AnchorProvider(connection, payerWallet, {
        commitment: 'confirmed',
    });

    // const programID = new PublicKey(AmmImplProgramId);
    // const poolPubkey = AmmImpl.deriveConstantProductPoolAddressWithConfig(tokenAMint, tokenBMint, config, programID);
    // // Create the pool
    // console.log('create pool %s', poolPubkey);
    let transactions = await AmmImpl.createPermissionlessConstantProductPoolWithConfig(
        provider.connection,
        payerWallet,
        new PublicKey(tokenAMint),
        tokenBMint,
        baseAmount,
        quoteAmount,
        config,
    );

    console.log("transactions", transactions.length);
    console.log("transactions", transactions);

    const instructions = [];

    for (const tx of transactions) {
        console.log("...tx.instructions", tx.instructions.length);
        instructions.push(...tx.instructions);
        const serializedTransaction = tx.serializeMessage();
        const transactionSize = Buffer.byteLength(serializedTransaction);
        console.log(`Transaction size before signing: ${transactionSize} bytes`);
    }

    console.log("instructions", instructions);
    let latestBlockhash = await connection.getLatestBlockhash();
    const messageV0 = new TransactionMessage({
        payerKey: payerWallet,
        recentBlockhash: latestBlockhash.blockhash,
        instructions: instructions,
    }).compileToV0Message();

    const versionedTransaction = new VersionedTransaction(messageV0);

    return versionedTransaction;


    // const transactions = await buildSimpleTransaction({
    //     connection,
    //     makeTxVersion: TxVersion.V0,
    //     payer: ownerPubkey,
    //     innerTransactions,
    // });

    // return transactions;
}

function fromAllocationsToAmount(lpAmount, allocations) {
    const sumPercentage = allocations.reduce((partialSum, a) => partialSum + a.percentage, 0);
    if (sumPercentage === 0) {
        throw Error('sumPercentage is zero');
    }

    let amounts = [];
    let sum = new BN(0);
    for (let i = 0; i < allocations.length - 1; i++) {
        const amount = lpAmount.mul(new BN(allocations[i].percentage)).div(new BN(sumPercentage));
        sum = sum.add(amount);
        amounts.push({
            address: allocations[i].address,
            amount,
        });
    }
    // the last wallet get remaining amount
    amounts.push({
        address: allocations[allocations.length - 1].address,
        amount: lpAmount.sub(sum),
    });
    return amounts;
}

function getSecondKey(key1, key2) {
    const buf1 = key1.toBuffer();
    const buf2 = key2.toBuffer();
    // Buf1 > buf2
    if (Buffer.compare(buf1, buf2) === 1) {
        return buf2;
    }
    return buf1;
}

function getFirstKey(key1, key2) {
    const buf1 = key1.toBuffer();
    const buf2 = key2.toBuffer();
    // Buf1 > buf2
    if (Buffer.compare(buf1, buf2) === 1) {
        return buf1;
    }
    return buf2;
}

async function getPoolAddress(tokenAMintString) {

    const tokenAMint = new PublicKey(tokenAMintString);
    const tokenBMint = new PublicKey('So11111111111111111111111111111111111111112');

    const programID = new PublicKey(PROGRAM_ID);
    const poolPubkey = derivePoolAddressWithConfig(tokenAMint, tokenBMint, config, programID);

    return poolPubkey.toString();
}

async function lockLiquidity(
    connection,
    payerWallet,
    tokenAMint,
    allocations,
) {
    const tokenBMint = new PublicKey('So11111111111111111111111111111111111111112');

    const programID = new PublicKey(AmmImpl.PROGRAM_ID);
    const poolPubkey = AmmImpl.deriveConstantProductPoolAddressWithConfig(tokenAMint, tokenBMint, config, programID);
    // Create the pool
    console.log('create pool %s', poolPubkey);

    // for (const transaction of transactions) {
    //     transaction.sign(payerWallet.payer);
    //     const txHash = await provider.connection.sendRawTransaction(transaction.serialize());
    //     await provider.connection.confirmTransaction(txHash, 'finalized');
    //     console.log('transaction %s', txHash);
    // }

    // Create escrow and lock liquidity
    const [lpMint] = PublicKey.findProgramAddressSync([Buffer.from(SEEDS.LP_MINT), poolPubkey.toBuffer()], programID);
    const payerPoolLp = await getAssociatedTokenAccount(lpMint, payerWallet.publicKey);
    const payerPoolLpBalance = (await connection.getTokenAccountBalance(payerPoolLp)).value.amount;
    console.log('payerPoolLpBalance %s', payerPoolLpBalance.toString());

    let allocationByAmounts = fromAllocationsToAmount(new BN(payerPoolLpBalance), allocations);
    const pool = await AmmImpl.create(connection, poolPubkey);
    let transactions = [];
    for (const allocation of allocationByAmounts) {
        console.log('Lock liquidity %s', allocation.address.toString());
        let transaction = await pool.lockLiquidity(allocation.address, allocation.amount, payerWallet.publicKey);
        transactions.push(transaction);
        // transaction.sign(payerWallet.payer);
        // const txHash = await provider.connection.sendRawTransaction(transaction.serialize());
        // await provider.connection.confirmTransaction(txHash, 'finalized');
        // console.log('transaction %s', txHash);
    }

    return transactions;
}

function derivePoolAddressWithConfig(
    tokenA,
    tokenB,
    config,
    programId,
) {
    const [poolPubkey] = PublicKey.findProgramAddressSync(
        [getFirstKey(tokenA, tokenB), getSecondKey(tokenA, tokenB), config.toBuffer()],
        programId,
    );

    return poolPubkey;
}

const createProgram = (connection, payerWallet) => {
    const provider = new AnchorProvider(connection, payerWallet, AnchorProvider.defaultOptions());
    console.log("createProgram 1", AmmIdl, AmmImplProgramId);


    const vaultProgram = new Program(VaultIdl, new PublicKey(VAULT_PROGRAM_ID), provider);
    console.log("createProgram 3");

    const ammProgram = new Program(AmmIdl, new PublicKey(AmmImplProgramId), provider);
    console.log("createProgram 2", VaultIdl, VAULT_PROGRAM_ID);


    return { provider, ammProgram, vaultProgram };
};

async function createATAPreInstructions(connection, owner, mintList) {
    return Promise.all(
        mintList.map((mint) => {
            return getOrCreateATAInstruction(mint, owner, connection);
        }),
    );
}

const getVaultPdas = (tokenMint, programId, seedBaseKey = null) => {

    console.log("getVaultPdas VAULT_BASE_KEY", VAULT_BASE_KEY.toString());

    const [vault, _vaultBump] = PublicKey.findProgramAddressSync([Buffer.from(SEEDS.VAULT_PREFIX), tokenMint.toBuffer(), (seedBaseKey !== null && seedBaseKey !== void 0 ? seedBaseKey : VAULT_BASE_KEY).toBuffer()], programId);
    const [tokenVault] = PublicKey.findProgramAddressSync([Buffer.from(SEEDS.TOKEN_VAULT_PREFIX), vault.toBuffer()], programId);
    const [lpMint] = PublicKey.findProgramAddressSync([Buffer.from(SEEDS.LP_MINT_PREFIX), vault.toBuffer()], programId);

    console.log("getVaultPdas vault", vault.toString());

    console.log("getVaultPdas tokenVault", tokenVault.toString());

    console.log("getVaultPdas lpMint", lpMint.toString());

    return {
        vaultPda: vault,
        tokenVaultPda: tokenVault,
        lpMintPda: lpMint,
    };
};


export async function getCreatePoolTx() {

}

async function searchPoolsByToken(ammProgram, tokenMint) {

    const [poolsForTokenAMint, poolsForTokenBMint] = await Promise.all([
        ammProgram.account.pool.all([
            {
                memcmp: {
                    offset: 8 + 32,
                    bytes: tokenMint.toBase58(),
                },
            },
        ]),
        ammProgram.account.pool.all([
            {
                memcmp: {
                    offset: 8 + 32 + 32,
                    bytes: tokenMint.toBase58(),
                },
            },
        ]),
    ]);

    return [...poolsForTokenAMint, ...poolsForTokenBMint];
}

export async function getSwapTx(
    connection,
    owner,
    inTokenMint,
    inAmount,
    payerWallet
) {

    console.log("inTokenMint", inTokenMint);

    const tokenAMint = NATIVE_MINT;
    const tokenBMint = inTokenMint;
    const inAmountLamport = new BN(Number(inAmount) * LAMPORTS_PER_SOL);

    const { vaultProgram, ammProgram } = createProgram(connection, payerWallet);

    const configAccount = await ammProgram.account.config.all(config);

    console.log("configAccount", configAccount);

    const poolsPubkey = await searchPoolsByToken(ammProgram, tokenBMint);

    console.log("poolsPubkey", poolsPubkey, poolsPubkey[0].publicKey.toString());

    const poolPubkey = poolsPubkey[0].publicKey;//derivePoolAddressWithConfig(tokenAMint, tokenBMint, config, ammProgram.programId);

    console.log("poolPubkey", poolPubkey, poolPubkey.toString());
    console.log("vaultProgram.programId", vaultProgram.programId, vaultProgram.programId.toString());

    const [
        { vaultPda: aVault, tokenVaultPda: aTokenVault, lpMintPda: aLpMintPda },
        { vaultPda: bVault, tokenVaultPda: bTokenVault, lpMintPda: bLpMintPda },
    ] = [getVaultPdas(tokenAMint, vaultProgram.programId), getVaultPdas(tokenBMint, vaultProgram.programId)];

    const [aVaultAccount, bVaultAccount] = await Promise.all([
        vaultProgram.account.vault.fetchNullable(aVault),
        vaultProgram.account.vault.fetchNullable(bVault),
    ]);

    let aVaultLpMint = aLpMintPda;
    let bVaultLpMint = bLpMintPda;

    if (!aVaultAccount) {
    } else {
        aVaultLpMint = aVaultAccount.lpMint; // Old vault doesn't have lp mint pda
    }
    if (!bVaultAccount) {
    } else {
        bVaultLpMint = bVaultAccount.lpMint; // Old vault doesn't have lp mint pda
    }


    console.log("amint", tokenAMint.toString());
    console.log("aVault", aVault.toString());
    console.log("aTokenVault", aTokenVault.toString());
    console.log("aLpMintPda", aLpMintPda.toString());

    console.log("bmint", tokenBMint.toString());
    console.log("bVault", bVault.toString());
    console.log("bTokenVault", bTokenVault.toString());
    console.log("bLpMintPda", bLpMintPda.toString());


    const [lpMint] = PublicKey.findProgramAddressSync(
        [Buffer.from(SEEDS.LP_MINT), poolPubkey.toBuffer()],
        ammProgram.programId,
    );

    const [[aVaultLp], [bVaultLp]] = [
        PublicKey.findProgramAddressSync([aVault.toBuffer(), poolPubkey.toBuffer()], ammProgram.programId),
        PublicKey.findProgramAddressSync([bVault.toBuffer(), poolPubkey.toBuffer()], ammProgram.programId),
    ];

    const [[protocolTokenAFee], [protocolTokenBFee]] = [
        PublicKey.findProgramAddressSync(
            [Buffer.from(SEEDS.FEE), tokenAMint.toBuffer(), poolPubkey.toBuffer()],
            ammProgram.programId,
        ),
        PublicKey.findProgramAddressSync(
            [Buffer.from(SEEDS.FEE), tokenBMint.toBuffer(), poolPubkey.toBuffer()],
            ammProgram.programId,
        ),
    ];

    const poolState = {
        tokenAMint: tokenAMint,
        tokenBMint: tokenBMint,
        protocolTokenAFee: protocolTokenAFee,
        protocolTokenBFee: protocolTokenBFee,
        aVault: aVault,
        bVault: bVault,
        aVaultLp: aVaultLp,
        bVaultLp: bVaultLp,
    };

    const [sourceToken, destinationToken] = tokenAMint.toString() == inTokenMint.toString()
        ? [poolState.tokenBMint, poolState.tokenAMint]
        : [poolState.tokenAMint, poolState.tokenBMint];

    const protocolTokenFee = tokenAMint.toString() == inTokenMint.toString()
        ? poolState.protocolTokenBFee
        : poolState.protocolTokenAFee;

    let preInstructions = [];
    const [[userSourceToken, createUserSourceIx], [userDestinationToken, createUserDestinationIx]] =
        await createATAPreInstructions(connection, owner, [sourceToken, destinationToken]);

    createUserSourceIx && preInstructions.push(createUserSourceIx);
    createUserDestinationIx && preInstructions.push(createUserDestinationIx);

    if (sourceToken.equals(NATIVE_MINT)) {
        preInstructions = preInstructions.concat(
            wrapSOLInstruction(owner, userSourceToken, new BN(inAmountLamport.toString())),
        );
    }

    const postInstructions = [];
    if (NATIVE_MINT.equals(destinationToken)) {
        const unwrapSOLIx = await unwrapSOLInstruction(owner);
        unwrapSOLIx && postInstructions.push(unwrapSOLIx);
    }

    // const remainingAccounts = swapCurve.getRemainingAccounts();

    console.log("inAmountLamport", inAmountLamport, inAmountLamport.toString());

    const swapTx = await ammProgram.methods
        .swap(inAmountLamport, new BN(0))
        .accounts({
            aTokenVault: aTokenVault,
            bTokenVault: bTokenVault,
            aVault: poolState.aVault,
            bVault: poolState.bVault,
            aVaultLp: poolState.aVaultLp,
            bVaultLp: poolState.bVaultLp,
            aVaultLpMint: aVaultLpMint,
            bVaultLpMint: bVaultLpMint,
            userSourceToken,
            userDestinationToken,
            user: owner,
            protocolTokenFee,
            pool: poolPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            vaultProgram: vaultProgram.programId,
        })
        .remainingAccounts([])
        .preInstructions(preInstructions)
        .postInstructions(postInstructions)
        .transaction();

    console.log("swapTx", swapTx);

    return new Transaction({
        feePayer: owner,
        ...(await connection.getLatestBlockhash(connection.commitment)),
    }).add(swapTx);
}

async function swap(connection, payerWallet, poolAddress, swapAmount, tokenAddress, swapAtoB = false) {

    const pool = await AmmImpl.create(connection, poolAddress);
    const poolInfo = pool.poolInfo;

    const poolTokenAddress = await pool.getPoolTokenMint();
    console.log('Pool LP Token Mint Address: %s', poolTokenAddress.toString());
    const lpSupply = await pool.getLpSupply();
    console.log('Pool LP Supply: %s', lpSupply.toNumber() / Math.pow(10, pool.decimals));
    const LockedLpAmount = await pool.getLockedLpAmount();
    console.log('Locked Lp Amount: %s \n', LockedLpAmount.toNumber());

    console.log(
        'tokenA %s Amount: %s ',
        pool.tokenAMint.address,
        poolInfo.tokenAAmount.toNumber() / Math.pow(10, pool.tokenAMint.decimals),
    );
    console.log(
        'tokenB %s Amount: %s ',
        pool.tokenBMint.address,
        poolInfo.tokenBAmount.toNumber() / Math.pow(10, pool.tokenBMint.decimals),
    );
    console.log('virtualPrice: %s \n', poolInfo.virtualPrice);

    let swapInToken = swapAtoB ? new PublicKey("So11111111111111111111111111111111111111112") : new PublicKey(tokenAddress);
    let swapOutToken = swapAtoB ? new PublicKey(tokenAddress) : new PublicKey("So11111111111111111111111111111111111111112");
    let inTokenMint = new PublicKey(swapInToken.address);
    let swapQuote = pool.getSwapQuote(inTokenMint, swapAmount, 100);
    console.log('🚀 ~ swapQuote:');
    console.log(
        'Swap In %s, Amount %s ',
        swapInToken.address,
        swapQuote.swapInAmount.toNumber() / Math.pow(10, swapInToken.decimals),
    );
    console.log(
        'Swap Out %s, Amount %s \n',
        swapOutToken.address,
        swapQuote.swapOutAmount.toNumber() / Math.pow(10, swapOutToken.decimals),
    );
    console.log(
        'Fee of the Swap %s %s',
        swapQuote.fee.toNumber() / Math.pow(10, swapInToken.decimals),
        swapInToken.address,
    );
    console.log('Price Impact of the Swap %s \n', swapQuote.priceImpact);

    console.log('Swapping...↔️ Please wait for a while😊☕️');

    const swapTx = await pool.swap(
        payerWallet,
        new PublicKey(swapInToken.address),
        swapAmount,
        swapQuote.minSwapOutAmount,
    );
    // const swapResult = await provider.sendAndConfirm(swapTx);

    // console.log('Swap Transaction Hash: %s ', swapResult);
    return { innerTransactions: swapTx.instructions, minAmountOut: Number(swapQuote.minSwapOutAmount) };
}